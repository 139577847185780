<template>
  <div v-if="cookieDialog" class="cookie-dialog">
    <p>
      We and selected third parties use cookies or similar technologies for technical purposes, solely to enhance functionality with your consent.<br/>
      You may grant, refuse, or withdraw your consent at any time by accessing this form. Should you choose to decline, please note that you will be unable to use this app.<br/><br/>

      Please click the "Agree" button to consent to the use of these technologies for functionality purposes only. If you choose to decline, you will not be able to access this app.<br/><br/>

      <router-link :to="{ path: '/privacy', query: $route.query }">
        <a class="epic-color">{{ "> Our data-privacy policy <" }}</a>
      </router-link>
    </p>
    <div class="spacer"/>
    <div class="buttons">
      <v-btn
          outlined color="var(--q-epic)"
          @click="agree"
      >
        Agree
      </v-btn>
      <v-btn
          class="decline"
          outlined color="var(--e-bad)"
          @click="decline"
      >
        Decline
      </v-btn>
    </div>
  </div>
</template>
<script>
import cookies from 'js-cookie';

export default {
  /* eslint-disable */
  name: "CookieDialog",

  data() {
    return {
      cookieDialog: true,
    };
  },
  methods: {
    agree() {
      this.cookieDialog = false;
      localStorage.setItem('cookie-dialog', 'false');
    },
    decline() {
      this.cookieDialog = true;
      localStorage.setItem('cookie-dialog', 'true');

      const allCookies = cookies.get();
      for (let cookieName in allCookies) { cookies.remove(cookieName); }

      window.location.href = "about:blank";
    },
  },
  created() {
    const storedDialogState = localStorage.getItem("cookie-dialog");
    if (storedDialogState !== null) this.cookieDialog = storedDialogState === "true";
  },
};
</script>
<style lang="scss">
.cookie-dialog {
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: #1212125f !important;
  backdrop-filter: blur(12px);
  padding: calc(0.75rem + 0.75vh + 0.75vw);
}
.cookie-dialog .buttons {
  position: relative;
  display: inline;
}
.cookie-dialog .buttons .decline {
  margin-left: calc(0.35rem + 0.35vh + 0.35vw);
}
</style>
