import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "@/views/LandingView.vue";

/* eslint-disable */
Vue.use(VueRouter);

const routes = [
    {
        path: "/landing",
        name: "Landing",
        component: Landing,
    },
    {
        path: "/",
        name: "Home",
        component: function () {
            return import(
                /* webpackChunkName: "Home" */ "../views/HomeView.vue"
                );
        },
    },
    {
        path: "/welcome",
        name: "Welcome",
        component: function () {
            return import(
                /* webpackChunkName: "Welcome" */ "../views/WelcomeView.vue"
                );
        },
    },
    {
        path: "/no-access",
        name: "No-Access",
        component: function () {
            return import(
                /* webpackChunkName: "NoAccess" */ "../views/NoAccessView.vue"
                );
        },
    },
    {
        path: "/calculator",
        name: "Calculator",
        component: function () {
            return import(
                /* webpackChunkName: "Calculator" */ "../views/CalculatorView.vue"
                );
        },
    },
    {
        path: "/sets",
        name: "Sets",
        component: function () {
            return import(/* webpackChunkName: "Sets" */ "../views/SetsView.vue");
        },
    },
    {
        path: "/racials",
        name: "Racials",
        component: function () {
            return import(/* webpackChunkName: "Racials" */ "../views/RacialsView.vue");
        },
    },
    {
        path: "/set/:gameId",
        name: "Set",
        component: function () {
            return import(/* webpackChunkName: "Set" */ "../views/SetView.vue");
        },
        props: (route) => ({
            gameId: route.params.gameId,
        }),
    },
    {
        path: "/about",
        name: "About",
        component: function () {
            return import(/* webpackChunkName: "About" */ "../views/AboutView.vue");
        },
    },
    {
        path: "/privacy",
        name: "PrivacyPolicy",
        component: function () {
            return import(/* webpackChunkName: "About" */ "../views/PrivacyPolicyView.vue");
        },
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFoundView.vue')
    }
];

const router = new VueRouter({
    mode: "history",
    routes,
});

import {useLoadingStore} from "../views/loading";
import {instance as auth} from "@/auth/index";
import {postToServer} from "@/scripts/serverCalls";

const routerConfig = require("./config.json");
const AUTHORIZED_ROLE = "ACCESS";

router.beforeEach(async (to, from, next) => {
    try {
        // Validating route change and waiting for load
        const isQueryParamChange =
            to.path === from.path &&
            JSON.stringify(to.params) === JSON.stringify(from.params);
        if (from.name && !isQueryParamChange) {
            const loadingStore = useLoadingStore();
            loadingStore.setLoading(true);
        }
        await waitForAuthLoading();

        // Skip authorization check for open routes
        if (routerConfig.open.includes(to.path)) {
            next();
            return;
        }

        // Authentication process
        else {
            // Fetching Cookie
            try {
                var token = JSON.parse(localStorage.getItem('token'));
            } catch (err) {
            }

            // Cookie exists
            if (token) {
                if (token.roles.includes(AUTHORIZED_ROLE)) {
                    next();
                    return;
                }
            }

            // Registering Cookie
            else if (auth._data.isAuthenticated) {
                await postToServer("/auth/register",
                    {
                        user: {
                            access_token: await auth.getTokenSilently(),
                            email: auth._data.user.email,
                        },
                    },
                    function (res) {
                        setTimeout(() => {
                            const token = res.data;
                            localStorage.setItem('token', JSON.stringify(token));
                            if (token.roles.includes(AUTHORIZED_ROLE)) {
                                next(to.path);
                                return;
                            }
                        }, 300);
                    },
                    false
                );
            }
        }

        if (routerConfig.defaultRedirect.from === to.path) {
            next(routerConfig.defaultRedirect.to);
            return;
        } else if (routerConfig.openAfterAuth.includes(to.path)) {
            next();
            return;
        }
        next(routerConfig.noAccess);
    } catch (error) {
        if (routerConfig.defaultRedirect.from === to.path) {
            next(routerConfig.defaultRedirect.to);
            return;
        } else if (routerConfig.openAfterAuth.includes(to.path)) {
            next();
            return;
        }
        next(routerConfig.error);
        return;
    }
});
router.afterEach((to, from) => {
    // Complete loading
    const loadingStore = useLoadingStore();
    setTimeout(() => {
        loadingStore.setLoading(false);
    }, 1000);
});

/**
 * Waits for Auth0 to load and resolves promise.
 */
async function waitForAuthLoading() {
    return new Promise((resolve) => {
        const intervalId = setInterval(() => {
            if (!auth._data.loading) {
                clearInterval(intervalId);
                resolve();
            }
        }, 100);
    });
}

export default router;
