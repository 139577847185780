<template>
  <v-app class="app">
    <h1 class="hide-seo-friendly">
      Elder Scrolls Online Stats .pro ESO Theorycrafting
    </h1>
    <v-app-bar
      v-if="
        !routerConfig.noAppNavigation.includes($route.path)
      "
      class="app navigation"
      app
      color="dark"
      dark
    >
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col align-self="center" class="d-flex align-center justify-start">
            <div class="navigation icon box">
              <svg
                width="calc(2rem + 2vw + 2vh)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                @click="$router.push('/')"
              >
                <path
                  fill="var(--primary-secondary)"
                  d="m80.97,10.74l-.94,4.2c-.88,3.88-2.62,7.97-5.08,11.88-7.71-7.9-18.5-12.81-30.42-12.81C21.04,14.01,2,33.04,2,56.53c0,4.73.77,9.28,2.2,13.53C1.5,63.92,0,57.13,0,50,0,22.39,22.39,0,50,0c11.7,0,22.46,4.02,30.97,10.74Z"
                />
                <path
                  fill="var(--primary-secondary)"
                  d="m100,50c0,27.61-22.39,50-50,50-4.09,0-8.06-.49-11.86-1.42,2.08.31,4.21.48,6.38.48,23.49,0,42.53-19.03,42.53-42.52,0-6.39-1.41-12.44-3.93-17.88.21-.24.42-.48.62-.72,3.72-4.39,6.72-9.09,8.93-13.98,4.64,7.59,7.32,16.51,7.32,26.04Z"
                />
                <path
                  fill="var(--primary-secondary)"
                  d="m91.23,21.72c-2.1,5-5.18,10.04-9.19,14.78-1.16,1.38-2.4,2.73-3.71,4.04-.43.43-.87.86-1.31,1.28-1.78.57-3.52,1.31-5.22,2.22-2.86,1.52-5.58,3.52-8.02,5.97-.82.82-1.59,1.68-2.31,2.56-.02.02-.04.04-.04.06-1.2,1.47-2.26,3.01-3.16,4.61-1.49,1.08-3.01,2.07-4.53,2.97-.28,6.42-3.18,11.47-7.37,12.07-.27.04-.54.06-.81.06.89-.19,1.7-.58,2.38-1.16,2.16-1.83,2.56-5.17,1.37-8.62-.39-1.19-.98-2.39-1.77-3.56-2.36,1.9-4.82,4.18-7.3,6.76-.63.64-1.26,1.31-1.88,1.99-.52.58-1.06,1.17-1.59,1.78-.47.54-.94,1.09-1.41,1.66-.16.19-.32.39-.48.58-2.06,2.49-3.91,4.97-5.56,7.39-2.43,2.27-3.92,4.68-4,6.51-.03.62.09,1.18.4,1.63,1.37,2.04,5.8,1.57,10.43-.96-1.99,3.67-5.66,6.46-10.11,7.58-.42.11-.87.21-1.31.28-.99.18-2.02.27-3.07.27h-.02c-6.5-.01-12.09-3.47-14.63-8.43.08.04.14.08.22.12h.01c1.64,1.08,3.59,1.7,5.68,1.7,6.04,0,10.94-5.26,10.94-11.76,0-.42-.02-.84-.07-1.26.01-.02.01-.04.01-.07,2.2-2.04,4.47-4.33,6.71-6.82.39-.42.77-.84,1.14-1.28.44-.5.88-1.01,1.32-1.52.19-.23.39-.47.59-.7.7-.83,1.38-1.67,2.03-2.5,1.53-1.92,2.96-3.84,4.26-5.72.63-.89,1.22-1.78,1.79-2.66.66-1,1.27-1.98,1.83-2.94,2.58-.2,5.28-.79,8.03-1.76,6.08-2.13,12.37-6.08,17.92-11.63,2.51-2.51,4.69-5.17,6.52-7.89.19-.28.37-.56.54-.82,2.8-4.34,4.72-8.82,5.68-13.08.16,2.7-.09,5.59-.72,8.56,2.17-2.37,4.1-4.83,5.78-7.34,3.17-4.69,5.46-9.53,6.82-14.23,1.03,5.76.02,12.49-2.83,19.31Z"
                />
              </svg>
            </div>
            <div class="navigation textbox">
              <h1 class="navigation icon text">ESO-Stats</h1>
            </div>
          </v-col>
          <v-col
            align-self="end"
            style="margin-bottom: calc(0.2rem + 0.2vw + 0.2vh)"
          >
            <v-container>
              <v-row justify="end">
                <v-col v-if="token.roles.includes('ACCESS')">
                  <router-link :to="{ path: '/sets', query: $route.query }"
                    ><span class="navigation nav-text">Sets</span></router-link
                  >
                </v-col>
                <v-col v-if="token.roles.includes('ACCESS')">
                  <router-link
                    :to="{ path: '/racials', query: $route.query }"
                    ><span class="navigation nav-text"
                      >Racials</span
                    ></router-link
                  >
                </v-col>
                <v-col>
                  <router-link :to="{ path: '/about', query: $route.query }"
                    ><span
                      class="navigation nav-text"
                      @click.native="$router.push('/about')"
                      >About</span
                    ></router-link
                  >
                </v-col>
                <v-col v-if="!token.roles.includes('ACCESS')">
                  <router-link :to="{ path: '/welcome', query: $route.query }"
                    ><span
                      class="navigation nav-text"
                      @click.native="$router.push('/welcome')"
                      >Log-In</span
                    ></router-link
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <router-view class="app content" />
    </v-main>
    <transition name="fadeInOut" v-on:enter="enter">
      <LoadingScreen style="z-index: 200" v-if="isLoading" />
    </transition>
    <CookieDialog/>
  </v-app>
</template>

<script>
/* eslint-disable */
import LoadingScreen from "./components/LoadingScreen.vue";
import { useLoadingStore } from "./views/loading";
import routerConfig from "./router/config.json";
import CookieDialog from "@/components/elements/CookiesDialog.vue";

export default {
  name: "App",

  components: {
    CookieDialog,
    LoadingScreen,
  },
  data: () => ({
    routerConfig,
    loadingStore: useLoadingStore()
  }),
  computed: {
    token() {
      try {
        const tokenStr = localStorage.getItem('token');
        return tokenStr ? JSON.parse(tokenStr) : {
          email: null,
          roles: []
        };
      } catch (error) {
        return {
          email: null,
          roles: []
        };
      }
    },
    isLoading() {
      return this.loadingStore.loading;
    },
  }
};
</script>
<style lang="scss">
@import "./appStyling.min.css";
</style>
