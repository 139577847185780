<template>
  <div class="main-div-vertical">
    <h1 class="hide-seo-friendly">
      ESO Stats ESO Sets Guides Builds Theorycrafting ESO-Hub
    </h1>
    <div class="page-section">
      <!-- Background Graphics -->
      <div
          class="page-section-background page-section-min-padding landing landing-img"
      >
        <div class="welcome titlelogo center">
          <div>
            <svg
                width="calc(2.25rem + 1.35vw - 0.35vh)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                @click="$router.push('/')"
            >
              <path
                  fill="var(--primary-secondary)"
                  d="m80.97,10.74l-.94,4.2c-.88,3.88-2.62,7.97-5.08,11.88-7.71-7.9-18.5-12.81-30.42-12.81C21.04,14.01,2,33.04,2,56.53c0,4.73.77,9.28,2.2,13.53C1.5,63.92,0,57.13,0,50,0,22.39,22.39,0,50,0c11.7,0,22.46,4.02,30.97,10.74Z"
              />
              <path
                  fill="var(--primary-secondary)"
                  d="m100,50c0,27.61-22.39,50-50,50-4.09,0-8.06-.49-11.86-1.42,2.08.31,4.21.48,6.38.48,23.49,0,42.53-19.03,42.53-42.52,0-6.39-1.41-12.44-3.93-17.88.21-.24.42-.48.62-.72,3.72-4.39,6.72-9.09,8.93-13.98,4.64,7.59,7.32,16.51,7.32,26.04Z"
              />
              <path
                  fill="var(--primary-secondary)"
                  d="m91.23,21.72c-2.1,5-5.18,10.04-9.19,14.78-1.16,1.38-2.4,2.73-3.71,4.04-.43.43-.87.86-1.31,1.28-1.78.57-3.52,1.31-5.22,2.22-2.86,1.52-5.58,3.52-8.02,5.97-.82.82-1.59,1.68-2.31,2.56-.02.02-.04.04-.04.06-1.2,1.47-2.26,3.01-3.16,4.61-1.49,1.08-3.01,2.07-4.53,2.97-.28,6.42-3.18,11.47-7.37,12.07-.27.04-.54.06-.81.06.89-.19,1.7-.58,2.38-1.16,2.16-1.83,2.56-5.17,1.37-8.62-.39-1.19-.98-2.39-1.77-3.56-2.36,1.9-4.82,4.18-7.3,6.76-.63.64-1.26,1.31-1.88,1.99-.52.58-1.06,1.17-1.59,1.78-.47.54-.94,1.09-1.41,1.66-.16.19-.32.39-.48.58-2.06,2.49-3.91,4.97-5.56,7.39-2.43,2.27-3.92,4.68-4,6.51-.03.62.09,1.18.4,1.63,1.37,2.04,5.8,1.57,10.43-.96-1.99,3.67-5.66,6.46-10.11,7.58-.42.11-.87.21-1.31.28-.99.18-2.02.27-3.07.27h-.02c-6.5-.01-12.09-3.47-14.63-8.43.08.04.14.08.22.12h.01c1.64,1.08,3.59,1.7,5.68,1.7,6.04,0,10.94-5.26,10.94-11.76,0-.42-.02-.84-.07-1.26.01-.02.01-.04.01-.07,2.2-2.04,4.47-4.33,6.71-6.82.39-.42.77-.84,1.14-1.28.44-.5.88-1.01,1.32-1.52.19-.23.39-.47.59-.7.7-.83,1.38-1.67,2.03-2.5,1.53-1.92,2.96-3.84,4.26-5.72.63-.89,1.22-1.78,1.79-2.66.66-1,1.27-1.98,1.83-2.94,2.58-.2,5.28-.79,8.03-1.76,6.08-2.13,12.37-6.08,17.92-11.63,2.51-2.51,4.69-5.17,6.52-7.89.19-.28.37-.56.54-.82,2.8-4.34,4.72-8.82,5.68-13.08.16,2.7-.09,5.59-.72,8.56,2.17-2.37,4.1-4.83,5.78-7.34,3.17-4.69,5.46-9.53,6.82-14.23,1.03,5.76.02,12.49-2.83,19.31Z"
              />
            </svg>
          </div>
          <div>
            <h1 class="welcome-title titlelogo">ESO-Stats</h1>
          </div>
          <div>
            <h2 class="home-title subtitle">
              The Most Grand Library of Elder Scrolls Online
            </h2>
            <div class="spacer"/>
            <span class="home-title subtitle">Aiming to give you the ultimate power for Theorycrafting</span>
            <div class="spacer"/>
            <v-container>
              <v-row justify="center" align-content="center">
                <v-col cols="auto">
                  <v-btn v-if="token.roles.includes('ACCESS')" outlined color="var(--q-epic)"
                         @click="$router.push('/')">
                    <v-icon size="calc(0.7rem + 0.2vw + 0.7vh)">mdi-home</v-icon>
                    To Home
                  </v-btn>
                  <v-btn v-else outlined color="var(--q-epic)" @click="$router.push('/welcome')">
                    <v-icon size="calc(0.7rem + 0.2vw + 0.7vh)">mdi-account</v-icon>
                    To Log-in
                  </v-btn>
                </v-col>
                <v-col v-if="token.roles.includes('ACCESS')" cols="auto">
                  <v-btn outlined color="var(--q-epic)" @click="logout()">
                    <v-icon size="calc(0.7rem + 0.2vw + 0.7vh)">mdi-account</v-icon>
                    Log-out
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                      outlined
                      color="var(--primary)"
                      @click="$router.push('/no-access')"
                  >
                    <v-icon size="calc(0.7rem + 0.2vw + 0.7vh)">mdi-hand-coin</v-icon>
                    Get Access
                  </v-btn>
                </v-col>
              </v-row>
              <v-row justify="center" align-content="center">
                <v-col cols="auto">
                  <div class="welcome-title usercount-container">
                    <div>
                      <span>{{ userCount.registered }} Registered Users</span>
                    </div>
                    <div>
                      <span>{{ userCount.access }} Beta-Testers</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section-adjustable">
      <div class="page-section-item text-center">
        <div>
          <h2 class="text-title bigtitle title-color">
            Gain Elite Knowledge
          </h2>
        </div>
        <div>
                <span>
                  Through mathematics we can calculate the most impactful builds.<br/>
                </span>
        </div>
        <div class="spacer"/>
        <div class="spacer"/>
        <div>
                <span>
                  Veterans of the game have long been using mathematics to calculate the best builds, which have the strength to dish out ridiculous DPS and face many PvP enemies at once.<br/>
                  This knowledge has now been compiled and automatized.
                </span>
          <div class="spacer"/>
          <div>
            <v-btn outlined color="var(--q-epic)" @click="$router.push('/about')">
              <v-icon size="calc(0.7rem + 0.2vw + 0.7vh)">mdi-information-outline</v-icon>
              Find Out More
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section-adjustable">
      <div class="page-section-item text-left">
        <v-container>
          <v-row>
            <v-col class="col-6">
              <div>
                <h2 class="text-title bigtitle title-color">
                  Be-Gone Outdated Wikis
                </h2>
              </div>
              <span>
                ESO-Stats is self-sustaining, thus always up-to-date.
              </span>
              <div class="spacer"/>
              <span>
                  Our lists rank items based on their damage, mitigation, sustain, stat-density & more.<br/>
                  Adapting to your current build in real-time! Testing its damage, mitigation, sustain etc. against simulated opponents.<br/>
            </span>
              <div class="spacer"/>
              <span>
          Our User Interface is pretty and minimalistic, and works on every device! Even your moms iPhone 3.
        </span>
            </v-col>
            <v-col class="col-6">
              <div style="position:relative;">
                <v-img
                    :aspect-ratio="1 / 1"
                    src="../../public/img/eso-stats_cloudrest.webp"
                />
                <div class="landing visual-accent">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 800 800"
                      width="calc(8rem + 8vw + 18vh)"
                  >
                    <g
                        stroke-width="4"
                        stroke="var(--primary-secondary)"
                        fill="none"
                        stroke-linecap="round"
                        transform="matrix(1,0,0,1,-5,84.75761413574219)"
                    >
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,589)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,558)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,527)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,496)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,465)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,434)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,403)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,372)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,341)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,310)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,279)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,248)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,217)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,186)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,155)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,124)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,93)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,62)"
                      ></path>
                      <path
                          d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                          transform="matrix(1,0,0,1,0,31)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="spacer"/>
    <div class="spacer"/>
    <div class="page-section-adjustable">
      <div class="page-section-item text-center">
        <div>
          <h2 class="text-title bigtitle title-color">
            Never-Seen-Before Features
          </h2>
        </div>
        <div class="spacer"/>
        <v-container>
          <v-row>
            <v-col class="col-4">
              <div>
                <v-icon
                    color="var(--q-legendary)"
                    size="calc(.8rem + .75vw + 1vh)"
                >mdi-script-text
                </v-icon>
              </div>
              <div class="spacer"/>
              <div>
                <span>
                  Lists of Sets, Racials and Foods<br/>
                  sortable by every dynamic metric.
                </span>
              </div>
            </v-col>
            <v-col class="col-4">
              <div>
                <v-icon
                    color="var(--q-legendary)"
                    size="calc(.8rem + .75vw + 1vh)"
                >mdi-fire
                </v-icon>
              </div>
              <div class="spacer"/>
              <div>
                <span>
                  Real-time simulation of dynamic metrics<br/>
                  including damage, DPS, mitigation, and sustain.
                </span>
              </div>
            </v-col>
            <v-col class="col-4">
              <div>
                <v-icon
                    color="var(--q-legendary)"
                    size="calc(.8rem + .75vw + 1vh)"
                >mdi-calculator-variant
                </v-icon>
              </div>
              <div class="spacer"/>
              <div>
                <span>
                  A calculator to simulate the impact<br/>
                  of each attribute on your in-game build.
                </span>
              </div>
            </v-col>
            <v-col class="col-4">
              <div>
                <v-icon
                    color="var(--q-legendary)"
                    size="calc(.8rem + .75vw + 1vh)"
                >mdi-fencing
                </v-icon>
              </div>
              <div class="spacer"/>
              <div>
                <span>
                  Compare <strong class="sets quality legendary">UESP-Wiki</strong> builds<br/>
                  and simulate their in-game impact. (WIP)
                </span>
              </div>
            </v-col>
            <v-col class="col-4">
              <div>
                <v-icon
                    color="var(--q-legendary)"
                    size="calc(.8rem + .75vw + 1vh)"
                >mdi-bow-arrow
                </v-icon>
              </div>
              <div class="spacer"/>
              <div>
                <span>
                  Compile a <strong class="sets quality legendary">UESP-Wiki</strong> build<br/>
                  and simulate its Trial DPS. (WIP)
                </span>
              </div>
            </v-col>
            <v-col class="col-4">
              <div>
                <DungeonIcon
                    color="var(--q-legendary)"
                    size="calc(.8rem + .75vw + 1vh)"
                />
              </div>
              <div class="spacer"/>
              <div>
                <span>
                  Supporting PvE and PvP.
                </span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="spacer"/>
    <div class="spacer"/>
    <div class="page-section-adjustable">
      <div class="page-section-item text-center">
        <div style="position:relative;">
          <youtube
              :player-width="this.$vssWidth > 1600 ? 1280 : (this.$vssWidth > 1280 ? 1024 : (this.$vssWidth > 780 ? 720 : (this.$vssWidth > 520 ? 480 : 300)))"
              :player-height="this.$vssWidth > 1600 ? 750 : (this.$vssWidth > 1280 ? 576 : (this.$vssWidth > 780 ? 405 : (this.$vssWidth > 520 ? 270 : 168.75)))"
              video-id="EzYrY_oYti4"
          />
          <div class="welcome visual-accent">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 800 800"
                width="calc(8rem + 8vw + 18vh)"
            >
              <g
                  stroke-width="4"
                  stroke="var(--primary-secondary)"
                  fill="none"
                  stroke-linecap="round"
                  transform="matrix(1,0,0,1,-5,84.75761413574219)"
              >
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,589)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,558)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,527)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,496)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,465)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,434)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,403)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,372)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,341)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,310)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,279)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,248)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,217)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,186)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,155)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,124)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,93)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,62)"
                ></path>
                <path
                    d="M10,10C26.435185185185183,11.458333333333334,53.93518518518518,20.75,88.88888888888889,17C123.84259259259258,13.25,140.74074074074073,-16.541666666666664,177.77777777777777,-8C214.8148148148148,0.5416666666666661,229.6296296296296,62.375,266.66666666666663,58C303.7037037037037,53.625,318.5185185185185,-30.458333333333332,355.55555555555554,-29C392.5925925925926,-27.541666666666668,407.40740740740745,66.04166666666667,444.44444444444446,65C481.48148148148147,63.958333333333336,496.29629629629625,-36.083333333333336,533.3333333333333,-34C570.3703703703703,-31.916666666666668,585.1851851851851,67.5,622.2222222222222,75C659.2592592592592,82.5,674.074074074074,5.958333333333333,711.1111111111111,2C748.1481481481482,-1.958333333333333,781.4814814814815,44.75,800,56"
                    transform="matrix(1,0,0,1,0,31)"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer"/>
    <div class="spacer"/>
    <div class="page-section-adjustable">
      <div class="page-section-item text-center">
        <div>
          <h2 class="text-title bigtitle title-color">
            Our Content-Creators
            <v-icon color="var(--q-legendary)" size="calc(.5rem + .55vw + .8vh)">mdi-heart</v-icon>
            It
          </h2>
        </div>
        <div class="spacer"/>
        <v-container>
          <v-row>
            <v-col class="text-left col-9">
              <ReviewItem
                  review="ESO-Stats is the most accurate way to determine the true value of sets, and I guarantee you'll discover new sets to use that you'd never considered before if you try it."
                  author="React Faster"
                  authorUrl="https://www.youtube.com/@ReactFaster"
                  :stars="5"
              />
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col class="text-right col-8">
              <ReviewItem
                  review="Wow, proc-sets are valued so accurately. I will definitely use this tool for theorycrafting."
                  author="Decimus"
                  authorUrl="https://www.youtube.com/@Decimus"
                  :stars="5"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-left col-6">
              <ReviewItem
                  review="ESO-Stats is a tool I use on a daily basis. It makes theorycrafting easier and it makes me gain alot of time, I can't theorycraft anymore without this website."
                  author="Kozy"
                  authorUrl="https://www.youtube.com/@kozy_eu"
                  :stars="5"
              />
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="spacer"/>
    <div class="spacer"/>
    <div class="page-section-adjustable funky-section" style="background-color: #151515;">
      <div class="page-section-item text-left">
        <v-container>
          <v-row>
            <v-col class="col-6">
              <div style="position:relative;">
                <v-img
                    :aspect-ratio="1 / 1"
                    src="../../public/img/eso-stats_galaxy.webp"
                />
              </div>
            </v-col>
            <v-col class="col-6">
              <div>
                <h2 class="text-title bigtitle title-color">
                  Discover the Unknown
                </h2>
              </div>
              <span>
                ESO-Stats is the #1 tool for finding <strong class="sets quality legendary">strong off-meta</strong> builds.
              </span>
              <div class="spacer"/>
              <span>
                  Be a pioneer in the community, find the niche or the unknown items and thrive!<br/>
                Be the first one to discover secret or forgotten strategies, delve into the world of theorycrafting with a tool giving you the power to analyse the entire game with one button-click.
            </span>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="page-section-adjustable">
      <div class="page-section-item text-center">
        <h2 class="text-title bigtitle title-color">
          Your Support is everything
        </h2>
        <span>
          ESO-Stats is a for-community, by-community project.<br/>
          Its development and hosting is entirely sponsored by the community, turning it non-profit.<br/>
          We appreciate every user who decided to use our services, you make this project possible.
        </span>
        <div class="spacer"/>
        <v-container>
          <v-row justify="center" align-content="center">
            <v-col cols="auto">
              <v-btn outlined color="var(--q-epic)" @click="$router.push('/welcome')">
                <v-icon size="calc(0.7rem + 0.2vw + 0.7vh)">mdi-account</v-icon>
                To Log-in
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                  outlined
                  color="var(--primary)"
                  @click="$router.push('/no-access')"
              >
                <v-icon size="calc(0.7rem + 0.2vw + 0.7vh)">mdi-hand-coin</v-icon>
                Get Access
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="page-section-adjustable">
      <div class="page-section-item text-center text-lowop-dimmed fine-print">
        <p class="welcome-title"
        >ESO-Sets is not associated with Zenimax Online Studios. It is a
          non-profit project made by community, for community, following TOS.<br/>
          Avalonia™ • DE363601833 • Altmarkt - Nordweg 2 03055 Cottbus •
          eso.stats.info@gmail.com</p
        >
        <router-link :to="{ path: '/privacy', query: $route.query }">
          <a>{{ "> Our data-privacy policy <" }}</a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DungeonIcon from "@/components/elements/DungeonIcon.vue";
import ReviewItem from "@/components/elements/ReviewItem.vue";
import {getFromServer} from "@/scripts/serverCalls";

export default {
  name: "LandingView",

  components: {
    DungeonIcon,
    ReviewItem
  },
  data() {
    return {
      playerOptions: {
        language: 'en',
        sources: [{
          type: "video/youtube",
          src: "https://youtu.be/Ebos_8_b7O8"
        }],
      },
      userCount: {
        registered: 0,
        access: 0
      },
    };
  },
  methods: {
    async fetchUserCount() {
      try {
        await getFromServer(`/user`, function (res) {
              this.userCount = res.data;
            }.bind(this)
        );
      } catch (error) {
        this.userCount = {
          registered: 0,
          access: 0
        };
      }
    },
    logout() {
      localStorage.removeItem('token');
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    },
  },
  computed: {
    token() {
      try {
        const tokenStr = localStorage.getItem('token');
        return tokenStr ? JSON.parse(tokenStr) : {
          email: null,
          roles: []
        };
      } catch (error) {
        return {
          email: null,
          roles: []
        };
      }
    },
  },
  mounted() {
    this.fetchUserCount();
    document.title = `Welcome to ESO-Stats`;
  },
};
</script>
<style>
@import "./page-template.scss";

.landing.landing-img {
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  background-image: url("/public/img/eso-stats_grandlibrary.jpg");
}

.landing.landing-img::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  backdrop-filter: blur(2px);
}

@media screen and (max-width: 1020px) {
  .landing.landing-img {
    background-position: center;
  }
}

.funky-section {
  overflow: hidden;
}

.funky-section::before {
  content: '';
  position: absolute;
  top: calc(-5rem - 35vw);
  left: calc(-5rem - 22vw);
  right: calc(-5rem - 33vw);
  bottom: calc(-5rem - 35vw);
  box-shadow: inset 0 0 15vw 15vw #B11565, inset 0 0 20vw 20vw var(--q-epic);
  border-radius: 50%;
  opacity: 0.7;
  animation: funky-section-animation 2s ease-in-out infinite alternate;
}

@keyframes funky-section-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3deg);
  }
}
</style>
