import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('loading', {
    state: () => ({ loading: false }),
    getters: {
        isLoading: (state) => state.loading
    },
    actions: {
        setLoading(val) {
            this.loading = val;
        }
    }
});