<template>
  <div class="reviews reviewbox">
    <p class="leftText reviews text productsans">
      {{ review }}
    </p>
    <p><v-icon
        v-for="n in stars"
        :key="n"
        size="calc(0.25rem + 0.3vw + 0.4vh)"
        color="var(--q-legendary)"
        >mdi-star</v-icon
      >
    </p>

    <div class="spacing" />
    <a :href="authorUrl" target="_blank"><p class="rightText reviews subtext smalltext productsansitalic">
      - {{ author }}
    </p></a>
  </div>
</template>

<script>
export default {
  name: "ReviewItem",
  props: ["review", "author", "authorUrl", "stars", "sourceIcon"],
};
</script>
<style lang="scss">
.reviews.text{
  color:var(--default-backgroundcolor);
}
.reviews.subtext{
  color:var(--default-backgroundcolor);
  opacity:0.75;
}
.reviews.title{
  color:var(--dark-textcolor);
}
.reviews.contentblock{
  z-index: 50;
  position:absolute;
    top: 60%;
    left: 50%;
    min-width: 75%;
    max-width:95%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding-bottom: 10rem;
}
.reviews.reviewbox{
  padding-right:calc(1rem + 1vw + 1vh);
  margin:calc(0.5rem + 0.5vw + 0.5vh);
}
</style>