import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
          light: {
            primary: '#B2AE8E',
            textPrimary: '#B2AE8E',
            background: '#121212',
          },
          dark: {
            primary: '#B2AE8E',
            textPrimary: '#B2AE8E',
            background: '#121212',

          }
        }
      }
});
