import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueCompositionAPI from '@vue/composition-api';
import VueScreenSize from 'vue-screen-size';

// Video Player
import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

// Auth0
import { domain, clientId, audience } from '../auth_config.json';
import { Auth0Plugin } from './auth';
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: audience
  },
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// Toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
    closeButton: false
};
Vue.use(Toast, options);

// Pinia
const pinia = createPinia()
Vue.use(PiniaVuePlugin);
Vue.use(VueCompositionAPI);

// ScreenSize
Vue.use(VueScreenSize);

// Config
Vue.config.productionTip = false
Vue.config.silent = true

/**
 * App start
 */
new Vue({
  router,
  vuetify,
  pinia,
  render: function (h) { return h(App) }
}).$mount('#app')
