import axios from "axios";
import router from "@/router";
import routerConfig from "../router/config.json";

/**
 * Checks if a given hostname represents a local IP address.
 * @param {string} hostname - The hostname to check.
 * @returns {boolean} - True if the hostname is a local IP address, false otherwise.
 */
function isLocalIpAddress(hostname) {
    return hostname === 'localhost' ||
        hostname.startsWith('127.') ||
        /^192\.168\./.test(hostname) ||
        /^10\./.test(hostname) ||
        /^172\.(1[6-9]|2[0-9]|3[0-1])\./.test(hostname);
}

/**
 * Determines the base URL for API requests based on the current hostname.
 */
const baseURL = isLocalIpAddress(window.location.hostname)
    ? process.env.VUE_APP_API_ADDRESS_DEV
    : process.env.VUE_APP_API_ADDRESS_PROD;

/**
 * Constructs the Authorization header using the token stored in cookies, if present.
 * @returns {Object} - An object containing the Authorization header, or an empty object if no token is found.
 */
function setAuthorizationHeader() {
    const headers = {};

    try {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token && token.token) {
            headers['Authorization'] = `Bearer ${token.token}`;
        }
    } catch (err) {
        if (isLocalIpAddress(window.location.hostname)) console.error("Error parsing authorization token:", err);
    }

    return headers;
}

/**
 * Sends a POST request to the server with the specified URL and data object, and handles the response with a callback.
 * @param {string} url - The API endpoint to send the request to.
 * @param {Object} obj - The data to send in the request body.
 * @param {Boolean} [redirect=true] - Route has redirect function on error.
 * @param {Function} _callback - The callback function to handle the response.
 */
export async function postToServer(url, obj, _callback, redirect = true) {
    const headers = setAuthorizationHeader();

    await axios({
        url: `${baseURL}${url}`,
        method: "post",
        headers: headers,
        data: obj,
        withCredentials: true
    })
        .then((res) => {
            if (res.status === 403) {
                handle403(redirect);
            } else _callback(res);
        })
        .catch((err) => {
            if (isLocalIpAddress(window.location.hostname)) console.log(err);
            errorHandler(err);
            _callback();
        });
}

/**
 * Sends a PUT request to the server with the specified URL and data object, and processes the response with a callback.
 * @param {string} url - The API endpoint to send the request to.
 * @param {Object} obj - The data to send in the request body.
 * @param {Boolean} [redirect=true] - Route has redirect function on error.
 * @param {Function} _callback - The callback function to handle the response.
 */
export async function putToServer(url, obj, _callback, redirect = true) {
    const headers = setAuthorizationHeader();

    await axios({
        url: `${baseURL}${url}`,
        method: "put",
        headers: headers,
        data: obj,
        withCredentials: true
    })
        .then((res) => {
            if (res.status === 403) {
                handle403(redirect);
            } else _callback(res);
        })
        .catch((err) => {
            if (isLocalIpAddress(window.location.hostname)) console.log(err);
            errorHandler(err);
            _callback();
        });
}

/**
 * Sends a GET request to the server at the specified URL and handles the response with a callback.
 * @param {string} url - The API endpoint to send the request to.
 * @param {Boolean} [redirect=true] - Route has redirect function on error.
 * @param {Function} _callback - The callback function to process the response.
 */
export async function getFromServer(url, _callback, redirect = true) {
    const headers = setAuthorizationHeader();

    await axios({
        url: `${baseURL}${url}`,
        method: "get",
        headers: headers,
    })
        .then((res) => {
            if (res.status === 403) {
                handle403(redirect);
            } else _callback(res);
        })
        .catch((err) => {
            if (err.response.data.code === 403) handle403(redirect);
            if (isLocalIpAddress(window.location.hostname)) console.log(err);
            errorHandler(err);
            _callback();
        });
}

/**
 * Handles errors from API requests.
 * @param {Object} err - The error object from an Axios request.
 */
function errorHandler(err) {
    if (err.response.status === 403) {
        handle403();
    }
}

/**
 * Handles 403 errors, redirecting user to authorization page.
 * @param {Boolean} redirect - Redirect functionality.
 */
function handle403(redirect){
    localStorage.removeItem('token');
    if(redirect) router.push(routerConfig.error);
}