<template>
  <!-- eslint-disable -->
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
    :width="size"
  >
    <path
      :fill="color"
      d="M7.32,66.57c0.1-1.02,0.2-2.04,0.29-3.05c0.15-1.55,0.29-3.1,0.44-4.65c0.14-1.47,0.27-2.94,0.41-4.41
		c0.15-1.55,0.29-3.1,0.44-4.65c0.14-1.47,0.27-2.94,0.41-4.41c0.15-1.55,0.29-3.1,0.44-4.65c0.14-1.5,0.28-3.01,0.42-4.51
		c0.13-1.44,0.26-2.88,0.41-4.32c0.03-0.27-0.04-0.43-0.25-0.6c-1.36-1.05-1.99-2.45-2.07-4.16c-0.04-0.92,0.14-1.81,0.26-2.71
		c0.22-1.76,0.47-3.52,0.71-5.28c0.27-1.96,0.54-3.92,0.81-5.89c0.2-1.46,0.4-2.93,0.61-4.39c0.03-0.21,0.13-0.41,0.21-0.61
		c0.06-0.16,0.16-0.31,0.22-0.47c0.83-2.38,2.5-3.79,4.95-4.28c0.6-0.12,1.21-0.19,1.82-0.19c13.51-0.01,27.03-0.01,40.54-0.01
		c7.25,0,14.5,0,21.76,0c1.11,0,2.19,0.23,3.21,0.67c1.63,0.69,2.68,1.93,3.32,3.55c0.11,0.29,0.28,0.56,0.39,0.85
		c0.08,0.2,0.13,0.42,0.16,0.63c0.35,2.51,0.69,5.01,1.03,7.52c0.33,2.41,0.65,4.83,0.98,7.24c0.1,0.76,0.23,1.52,0.31,2.29
		c0.2,2.03-0.28,3.81-1.94,5.14c-0.3,0.24-0.39,0.47-0.35,0.84c0.17,1.64,0.32,3.29,0.46,4.93c0.03,0.37,0.13,0.58,0.53,0.71
		c1.38,0.44,2.36,1.41,3.15,2.58c0.16,0.24,0.26,0.55,0.28,0.83c0.06,0.96,0.07,1.92,0.09,2.87c0.01,0.2,0,0.4,0,0.65
		c0.22,0.01,0.41,0.02,0.59,0.02c0.82,0.02,1.63,0.04,2.45,0.06c0.65,0.01,1.16,0.33,1.65,0.73c1.41,1.15,2.17,2.64,2.41,4.44
		c0.4,3.09,0.42,6.18,0.06,9.27c-0.11,0.96-0.29,1.9-0.73,2.77c-0.55,1.1-1.38,1.95-2.41,2.59c-0.29,0.18-0.68,0.26-1.03,0.27
		c-1.04,0.05-2.07,0.04-3.11,0.05c-0.13,0-0.25,0-0.41,0c-0.01,0.13-0.04,0.25-0.04,0.37c0,0.31,0.02,0.63-0.01,0.94
		c-0.04,0.48,0.05,0.86,0.47,1.18c0.39,0.29,0.58,0.77,0.77,1.22c0.59,1.35,0.84,2.79,1.12,4.23c0.74,3.83,1.49,7.65,2.23,11.48
		c0.38,1.94,0.82,3.88,1.12,5.83c0.17,1.15,0.12,2.33-0.37,3.43c-0.46,1.02-1.18,1.81-2.14,2.39c-0.89,0.54-1.89,0.7-2.9,0.72
		c-1.7,0.04-3.4,0.03-5.09,0.03c-5.3,0-10.6-0.03-15.9-0.02c-1.41,0-2.57-0.39-3.33-1.69c-0.39,0-0.78,0-1.17,0
		c-3.78-0.02-7.55-0.05-11.33-0.07c-3.46-0.02-6.92-0.03-10.38-0.02c-4.15,0.01-8.31,0.06-12.46,0.09c-0.13,0-0.25,0.01-0.38,0
		c-0.52-0.04-0.89,0.1-1.23,0.56c-0.56,0.77-1.42,1.07-2.35,1.1c-0.72,0.03-1.45,0.01-2.17,0.01c-4.69,0.01-9.38,0.02-14.06,0.03
		c-1.37,0-2.74,0.03-4.11,0.02c-1.02-0.01-2.04-0.05-3.04-0.32c-1.88-0.5-3.47-2.42-3.58-4.36c-0.03-0.5-0.1-1.01-0.06-1.5
		c0.04-0.54,0.18-1.08,0.29-1.62c0.74-3.81,1.48-7.62,2.22-11.43c0.45-2.31,0.86-4.64,1.36-6.94c0.19-0.85,0.56-1.66,0.91-2.47
		c0.27-0.62,0.79-1.05,1.41-1.34C7.13,66.65,7.21,66.61,7.32,66.57z M31.31,30.06c-0.14-0.06-0.22-0.1-0.31-0.13
		c-1.42-0.52-2.83-1.04-4.25-1.57c-0.23-0.09-0.44-0.23-0.65-0.37c-0.15-0.1-0.29-0.25-0.2-0.43c0.07-0.14,0.23-0.27,0.37-0.31
		c0.28-0.08,0.59-0.12,0.88-0.12c1.92-0.01,3.84-0.01,5.76-0.01c10.68,0,21.36,0,32.05,0c1.9,0,3.81,0,5.71,0.02
		c0.31,0,0.63,0.05,0.92,0.14c0.37,0.12,0.44,0.43,0.14,0.67c-0.2,0.17-0.43,0.31-0.68,0.41c-1.37,0.52-2.74,1.02-4.11,1.52
		c-0.44,0.16-0.45,0.16-0.4,0.64c0.08,0.91,0.19,1.81,0.28,2.72c0.17,1.66,0.34,3.32,0.51,4.97c0.18,1.74,0.36,3.47,0.54,5.21
		c0.03,0.26,0.08,0.52,0.11,0.75c1.21,0,2.36,0,3.57,0c0-0.23,0-0.42,0-0.6c0.02-0.72,0.01-1.45,0.06-2.17
		c0.03-0.35,0.09-0.74,0.27-1.03c0.69-1.1,1.59-1.99,2.8-2.51c0.65-0.28,1.33-0.48,2.04-0.55c1.22-0.12,2.44-0.21,3.66-0.31
		c0.21-0.02,0.42-0.05,0.67-0.08c-0.04-0.49-0.08-0.93-0.12-1.36c-0.14-1.5-0.29-3-0.43-4.51c-0.09-0.97-0.19-1.94-0.27-2.91
		c-0.03-0.32,0.05-0.42,0.37-0.49c0.14-0.03,0.28-0.03,0.42-0.07c0.38-0.11,0.75-0.21,1.12-0.35c0.36-0.14,0.55-0.41,0.5-0.81
		c-0.03-0.26-0.05-0.53-0.09-0.79c-0.05-0.29-0.15-0.58-0.19-0.87c-0.27-1.85-0.53-3.7-0.79-5.55c-0.27-1.91-0.55-3.83-0.81-5.74
		c-0.11-0.83-0.22-1.65-0.72-2.36c-0.27-0.39-0.59-0.68-1.04-0.84c-0.48-0.18-0.95-0.25-1.47-0.25c-7,0.05-14,0.1-21,0.11
		c-5.58,0.01-11.17-0.03-16.75-0.05c-4.75-0.01-9.5-0.02-14.25-0.04c-1.92-0.01-3.84-0.04-5.76-0.03c-0.6,0.01-1.2,0.22-1.64,0.64
		c-0.5,0.47-0.84,1.08-0.95,1.76c-0.31,2.02-0.58,4.04-0.87,6.07c-0.27,1.9-0.54,3.8-0.81,5.69c-0.1,0.68-0.26,1.36-0.35,2.04
		c-0.09,0.63,0.03,0.87,0.63,1.1c0.41,0.16,0.84,0.24,1.27,0.33c0.46,0.1,0.52,0.15,0.49,0.63c-0.03,0.44-0.09,0.88-0.13,1.31
		c-0.14,1.5-0.29,3-0.43,4.51c-0.14,1.46-0.28,2.91-0.42,4.37c-0.14,1.47-0.29,2.94-0.43,4.41c-0.14,1.47-0.27,2.94-0.41,4.41
		c-0.17,1.8-0.35,3.6-0.53,5.4c-0.17,1.8-0.33,3.6-0.51,5.4c-0.17,1.82-0.36,3.63-0.53,5.45c-0.2,2.13-0.4,4.26-0.6,6.39
		c-0.04,0.38-0.07,0.75-0.12,1.13c-0.04,0.33-0.2,0.52-0.56,0.6c-0.43,0.09-0.84,0.26-1.26,0.4c-0.24,0.08-0.39,0.25-0.49,0.48
		c-0.2,0.48-0.34,0.97-0.44,1.48c-1,5.1-2.02,10.2-3.03,15.31c-0.04,0.18-0.06,0.36-0.09,0.58c6.55,0.05,13.04,0.1,19.59,0.14
		c0.64-5.3,1.26-10.55,1.89-15.83c-0.22-0.05-0.39-0.09-0.55-0.12c-1.85-0.27-3.7-0.53-5.55-0.79c-0.25-0.04-0.5-0.07-0.74-0.12
		c-0.2-0.04-0.36-0.14-0.36-0.37c0-0.23,0.16-0.32,0.37-0.36c0.05-0.01,0.09-0.01,0.14-0.02c1.11-0.15,2.21-0.31,3.32-0.46
		c0.45-0.06,0.89-0.13,1.32-0.2C28.43,57.83,29.87,43.96,31.31,30.06z M70.63,89.99c6.54,0.03,13.04-0.12,19.59-0.11
		c-0.05-0.32-0.09-0.58-0.14-0.84c-0.93-4.71-1.87-9.43-2.81-14.14c-0.14-0.72-0.24-1.45-0.49-2.16c-0.24-0.69-0.97-0.94-1.53-0.9
		c-1.11,0.08-2.23,0.18-3.34,0.22c-1.84,0.07-3.68-0.07-5.51-0.28c-1.81-0.21-3.35-0.91-4.54-2.33c-0.49-0.58-0.84-1.2-0.83-1.99
		c0.01-0.79-0.03-1.57-0.03-2.36c-0.01-0.76-0.04-0.8-0.81-0.69c-0.03,0-0.05,0.03-0.12,0.08c0.25,2.39,0.5,4.8,0.75,7.23
		c0.16,0.04,0.3,0.08,0.43,0.1c1.23,0.17,2.46,0.33,3.69,0.5c0.22,0.03,0.44,0.06,0.65,0.1c0.2,0.03,0.37,0.13,0.37,0.36
		c0,0.23-0.17,0.32-0.36,0.37c-0.09,0.02-0.18,0.04-0.28,0.05c-1.84,0.26-3.67,0.52-5.51,0.79c-0.35,0.05-0.7,0.12-1.08,0.19
		C69.37,79.46,70,84.7,70.63,89.99z M77.42,65.96c1.21,0.12,7.15,0.1,7.76-0.03c0.01-0.13,0.03-0.27,0.03-0.41
		c0.03-1.98,0.06-3.96,0.08-5.94c0-0.16-0.01-0.32,0.02-0.47c0.07-0.4,0.26-0.55,0.67-0.56c2.2-0.03,4.4-0.06,6.6-0.1
		c0.14,0,0.28-0.02,0.4-0.03c0.19-0.68,0.19-7.08,0-7.76c-0.14-0.01-0.3-0.02-0.45-0.03c-1.97-0.03-3.93-0.06-5.9-0.08
		c-1.08-0.01-1.21-0.14-1.23-1.23c-0.02-1.89-0.03-3.77-0.05-5.66c0-0.18-0.02-0.37-0.04-0.6c-2.62-0.09-5.19-0.09-7.79,0
		c-0.01,0.25-0.03,0.43-0.03,0.62c-0.02,1.9-0.03,3.81-0.05,5.71c0,0.22-0.02,0.44-0.07,0.65c-0.07,0.29-0.24,0.44-0.47,0.45
		c-0.36,0.01-0.72,0.03-1.08,0.03c-1.87,0.02-3.74,0.03-5.61,0.05c-0.18,0-0.36,0.03-0.57,0.05c0,2.61,0,5.17,0,7.77
		c0.22,0.02,0.41,0.04,0.59,0.04c1.89,0.02,3.77,0.03,5.66,0.05c0.25,0,0.5,0.03,0.75,0.06c0.4,0.05,0.58,0.24,0.63,0.62
		c0.01,0.11,0.01,0.22,0.01,0.33c0.03,2,0.05,3.99,0.08,5.99C77.4,65.63,77.41,65.79,77.42,65.96z M34.8,83.68
		c-0.26,0.77-0.43,1.57-0.63,2.35c-0.2,0.78-0.45,1.55-0.57,2.35c0.75,0.13,30.2,0.1,30.64-0.03c-0.4-1.55-0.78-3.12-1.22-4.68
		C53.59,83.68,44.22,83.68,34.8,83.68z M39.8,78.12c-0.36,0.76-1.02,2.8-1.03,3.15c6.77,0.1,13.53,0.1,20.37,0
		c-0.38-1.1-0.74-2.13-1.09-3.15C51.93,78.01,45.88,78.01,39.8,78.12z M54.77,73.78c-0.16-0.01-0.3-0.03-0.44-0.03
		c-3.03-0.01-6.07-0.03-9.1-0.04c-0.63,0-1.26,0.02-1.89,0.04c-0.16,0-0.33,0-0.38,0.2c-0.19,0.66-0.38,1.32-0.58,2.03
		c4.35,0,8.65,0,13.03,0C55.21,75.21,55.02,74.49,54.77,73.78z"
    />
  </svg>
</template>
<script>
export default {
  name: "DungeonIcon",

  props: ["size", "color"],
};
</script>
